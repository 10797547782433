<template>
    <div class="panel panel-default toggle_container">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date From</label>
                <DatePicker v-model="state.optionFields.shipDateFrom.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date To</label>
                <DatePicker v-model="state.optionFields.shipDateTo.val" :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Item</label>
                <Typeahead cdi_type="curb" :displayValue="state.optionFields.itemName.val" v-bind:ID.sync="state.optionFields.itemId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select v-model="state.optionFields.cdiLocationId.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>State</label>
                <select v-model="state.optionFields.state.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Territory</label>
                <select class="form-control" v-model="state.optionFields.territory.val">
                    <option value="0">All</option>
                    <option v-for="[id, territory] in cache.salesCache.SALES_TERRITORIES" v-bind:key="id" :value="id">{{ territory }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Job Tag</label>
                <input type='text' class='form-control' v-model="state.optionFields.jobTag.val">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Cabinet Size</label>
                <select class="form-control" v-model="state.optionFields.cabinetSize.val">
                    <option value="0">All</option>
                    <option v-for="[id, cabinetSize] in cache.curbCache.CABINET_SIZE" v-bind:key="id" :value="id">{{ cabinetSize }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Old</label>
                <Typeahead ref="old" cdi_type="part" v-bind:ID.sync="state.optionFields.oldPartId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>New</label>
                <Typeahead ref="new" cdi_type="part" v-bind:ID.sync="state.optionFields.newPartId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Brand</label>
                <select class="form-control" v-model="state.optionFields.brand.val">
                    <option value="0">All</option>
                    <option v-for="[id, brand] in cache.brandCache.BRANDS" v-bind:key="id" :value="id">{{ brand }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Trucking</label>
                <select v-model="state.optionFields.trucking.val" class="form-control">
                    <option value="0">None</option>
                    <option v-for="[id, option] in cache.shippingCache.SHIPPING_OPTIONS" v-bind:key="id" :value="id">{{ option }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Sales Person</label>
                <Typeahead ref="salesPerson" cdi_type="sales_employee" :displayValue="state.optionFields.salesPersonName.val" v-bind:ID.sync="state.optionFields.salesPersonId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Customer</label>
                <Typeahead ref="customer" cdi_type='user' v-bind:ID.sync="state.optionFields.distributorId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Distributor City</label>
                <Typeahead ref="distributorCity" cdi_type='city' v-bind:ID.sync="state.optionFields.distributorCity.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Special Tag</label>
                <select v-model="state.optionFields.specialTag.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, option] in cache.userCache.USER_SPECIAL_TAGS" v-bind:key="id" :value="id">{{ option }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.turretReady.val" :true-value="1" :false-value=null > Turret Ready</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.custom.val" :true-value="1" :false-value=null > Custom</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.kit.val" :true-value="1" :false-value=null > Kit</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.notKit.val" :true-value="1" :false-value=null > Not Kit</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.conforming.val" :true-value="1" :false-value=null > Conforming</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.nonConforming.val" :true-value="1" :false-value=null > Non-Conforming</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.applied.val" :true-value="1" :false-value=null > Applied</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.nonApplied.val" :true-value="1" :false-value=null > Non-Applied</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.sectional.val" :true-value="1" :false-value=null > Sectional</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.nonSectional.val" :true-value="1" :false-value=null > Non-Sectional</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.insulationKit.val" :true-value="1" :false-value="null" > Insulation Kit</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.nonInsulationKit.val" :true-value="1" :false-value=null > Non-Insulation Kit</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.usedEstimator.val" :true-value="1" :false-value=null> Used Estimator</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.noEstimator.val" :true-value="1" :false-value=null> No Estimator</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.knockdown.val" :value='10' :false-value=null> Knockdowns</label>
            </div>

            <div class='col-xs-12'><div class="filter_separator" v-show="!toggleHide">Item Type <small>(All if none are checked)</small></div></div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide"   v-for="[curbTypeHistoricalId, curbTypeHistorical] in cache.curbTypeCache.CURB_TYPES_HISTORICAL" v-bind:key="curbTypeHistoricalId + curbTypeHistorical" >
                <label ><input type=checkbox :true-value="1" v-model="state.optionFields.curb_type.val" :false-value=null :value="curbTypeHistoricalId"> {{ curbTypeHistorical }}</label>
            </div>

            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.includeCdiStock.val" :true-value="1" :false-value=null> Include CDI Stock</label>
            </div>

            <div class='col-xs-12' v-show="!toggleHide"><div class="filter_separator">Additional Data</div></div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.showCatalog.val" :true-value="1" :false-value=null > Catalog Numbers</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox v-model="state.optionFields.showModels.val" :true-value="1" :false-value=null > Unit Model Numbers</label>
            </div>

            <div class="col-xs-12"><button type="button" @click="toggleMoreFilters" class="btn btn-default btn-sm">{{ (toggleHide) ? 'More' : 'Less' }} Filters</button></div>
        </div>
    </div>
</template>

<script>

    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import DatePicker from "../utils/DatePicker";
    import curbTypeCache from "@/cache/curbType.cache.js";
    import stateCache from "@/cache/state.cache.js";
    import salesCache from "@/cache/salesData.cache.js";
    import brandCache from "@/cache/brand.cache.js";
    import shippingCache from "@/cache/shipping.cache.js";
    import userCache from "@/cache/user.cache.js";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    import curbCache from "@/cache/curb.cache";

    export default {
        name: 'Options',
        components: {DatePicker, Typeahead},
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    curbTypeCache,
                    stateCache,
                    salesCache,
                    brandCache,
                    shippingCache,
                    userCache,
                    curbCache
                }
            }
        },
        methods: {
            toggleMoreFilters: function() {
                this.toggleHide = !this.toggleHide;
                if(this.toggleHide){
                    this.$refs.old.object = '';
                    this.$refs.customer.object ='';
                    this.$refs.distributorCity.object = '';
                    this.$refs.salesPerson.object = '';
                    this.$refs.new.object = '';
                }
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }

</script>