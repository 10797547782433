<template>
    <div>
        <h1>Item Sales</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <CompareBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store.js";
    import Options from "./curb_sales_opt";
    import GroupBy from "./utils/GroupBy";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    import DateHelpers from "@/store/DateHelpers";
    import CompareBy from "./utils/CompareBy"
    export default {
        name: 'curb_sales',
        components: {
            Options,
            GroupBy,
            Fields,
            CompareBy,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    curb_type: {val: [], qsField: '[in][c.curb_type_id]', defaultVal: []},
                    shipDateFrom: {val: DateHelpers.getFirstOfTheMonth(), qsField: '[dates][actual_ship_from]'},
                    shipDateTo: {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                    itemId: {val: (this.$route.query.curb_search_id) ? this.$route.query.curb_search_id : 0, qsField: '[numbers][c.curb_id]'},
                    itemName: {val: (this.$route.query.curb_search) ? this.$route.query.curb_search : ''},
                    cdiLocationId: {val: 0, qsField: '[numbers][t.cdi_location_id]'},
                    state: {val: 0, qsField: '[strings][sa.state]'},
                    territory: {val: 0, qsField: '[numbers][st.territory_id]'},
                    companyId: {val: 0, qsField: '[numbers][d.company_id]'},
                    jobTag: {val: '', qsField: '[fstrings][job_tag]'},
                    cabinetSize: {val:0, qsField:'[numbers][c.curb_price_category_id]'},
                    oldPartId: {val: 0, qsField: '[numbers][c.cdi_part_id_old]', defaultVal: 0},
                    newPartId: {val: 0, qsField: '[numbers][c.cdi_part_id_new]', defaultVal: 0},
                    brand: {val: 0, qsField: '[numbers][br.brand_id]', defaultVal: 0},
                    trucking: {val: 0, qsField: '[numbers][trucking_options_id]', defaultVal: 0},
                    salesPersonId: {val: (this.$route.query.tracker_sales_user_id) ? this.$route.query.tracker_sales_user_id : 0, qsField: '[subquery][tracker_sales_user]'},
                    salesPersonName: {val: (this.$route.query.tracker_sales_user) ? this.$route.query.tracker_sales_user : ''},
                    distributorId: {val: '', qsField: '[numbers][t.distributor_id]', defaultVal: ''},
                    distributorCity: {val: '', qsField: '[strings][da.city]', defaultVal: ''},
                    specialTag: {val: 0, qsField: '[numbers][d.branch_id]', defaultVal: 0},
                    turretReady: {val: '', qsField: '[isset][c.turret_ready]', defaultVal: ''},
                    custom: {val: '', qsField: '[isset][ti.custom_num]', defaultVal: ''},
                    kit: {val: '', qsField: '[isnot][c.not_kit]', defaultVal: ''},
                    notKit: {val: '', qsField: '[isset][c.not_kit]', defaultVal: ''},
                    conforming: {val: '', qsField: '[isset][c.conforming]', defaultVal: ''},
                    nonConforming: {val: '', qsField: '[isnot][c.conforming]', defaultVal: ''},
                    applied: {val: '', qsField: '[isset][ti.applied]', defaultVal: ''},
                    nonApplied: {val: '', qsField: '[isnot][ti.applied]', defaultVal: ''},
                    sectional: {val: '', qsField: '[isset][ti.sectional]', defaultVal: ''},
                    nonSectional: {val: '', qsField: '[isnot][ti.sectional]', defaultVal: ''},
                    insulationKit: {val: '', qsField: '[isset][c.insulation_kit]', defaultVal: ''},
                    nonInsulationKit: {val: '', qsField: '[isnot][c.insulation_kit]', defaultVal: ''},
                    usedEstimator: {val: '', qsField: '[isset][ti.curb_estimate_id]', defaultVal: ''},
                    noEstimator: {val: '', qsField: '[isnot][ti.curb_estimate_id]', defaultVal: ''},
                    knockdown: {val: [], qsField: '[in][c.side_type_id]', defaultVal: []},
                    includeCdiStock: {val: '', qsField: '[include_cdi_stock]', defaultVal: ''},
                    showCatalog: {val: '', qsField: '[show_catalog]', defaultVal: ''},
                    showModels: {val: '', qsField: '[show_models]', defaultVal: ''},
                    compareByPeriod: {val:1, label: 'Previous Year', qsField: '[period_type]'},
                    compareByPeriodFields: {
                      prev_period: {val:1, label: 'Previous Year', qsField: '[period_type]'},
                      prev_year: {val:2, label: 'Previous Period', qsField:'[period_type]'},
                    },
                    numPeriod: {val: 2, label: '1', qsField: ['date_compare_period']},
                    numPeriods: {
                      two: {val: 2, label: '2', qsField: ['date_compare_periods']},
                      three: {val: 3, label: '3', qsField: ['date_compare_periods']},
                      four: {val: 4, label: '4', qsField: ['date_compare_periods']},
                      five: {val: 5, label: '5', qsField: ['date_compare_periods']},
                    },
                },
                groupByFields: {
                    curb_type: { label: 'Item Type', checked: true },
                    nomenclature: { label: 'Item', checked: true },
                    cdi_location: { label: 'Location' },
                    company_name: { label: 'Company' },
                    shipto_state: { label: 'Shipto State' },
                    customer_state: { label: 'Customer State' },
                    shipto_territory: { label: 'Territory' },
                    price_level: { label: 'Level' },
                    max_tonnage: { label: 'Tonnage' },
                    conforming: { label: 'Conforming' },
                    applied: { label: 'Applied' },
                    sectional: { label: 'Sectional' },
                    manufacturing_days: { label: 'Build Days' },
                    dist_city: { label: 'Customer City' },
                    sales_person: { label: 'Sales Person' },
                    cabinet_size: { label: 'Cabinet Size' }
                },
                dataFields: {
                    tracker_id: {label: 'Order', checked: true},
                    curb_type: {label: 'Item Type', checked: false},
                    nomenclature: {label: 'Item', checked: true},
                    discount: {label: 'Discount (Dis)', checked: true},
                    remake: {label: 'Remake (R)', checked: true},
                    volume_discount: {label: 'Volume Discount (Vol)', checked: true},
                    expedited: {label: 'Expedited (Ex)', checked: true},
                    curb_price: {label: 'Curb Price', checked: false},
                    // metal_total: {label: 'Est Materials', checked: true},
                    // other_material_total: {label: 'Est Other', checked: true},
                    // labor_total: {label: 'Est Labor', checked: true},
                    // freight_total: {label: 'Est Freight', checked: true},
                    sale_total:{label: 'Sale', checked: true},
                    // margin_dollars: {label: 'Est Margin', checked: true},
                    price_category: {label: 'Cabinet Size', checked: false},
                    company_name: {label: 'Company', checked: false},
                    cdi_location: {label: 'Location', checked: false},
                    state: {label: 'Shipto State', checked: false},
                    customer_state: {label: 'Customer State', checked: false},
                    ship_date: {label: 'Ship Date', checked: false}
                },
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Item Sales']);
            this.$set(store.state.results, this.$options.name, '');
            store.state.isGroupBy = true;
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        },
        destroyed() {
            this.state.isCompareBy = false;
        }
    }
</script>